@import '../../assets/style/base/colorsTheme.scss';
@import '../../assets/style/base/designTheme.scss';
@import '../../style/globalStyles.scss';

.header-toolbar {
  width: calc(100% - 350px); //width of name/info/lang header block

  &:hover {
    cursor: default;
  }

  &.version-on {
    width: calc(100% - 470px); //width of name/info/lang/version header block
  }

  &.with-logo {
    padding-left: 18px;
    min-height: 52px;
  }

  &--wrapper {
    background-color: $toolbarBackground !important;
    box-shadow: unset !important;
    height: 55px;
  }

  &--logo {
    height: $header-toolbar-logo-height;
  }

  &--logout-link {
    @include font-builder(16px, 400);
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  &.full-width {
    width: 100%;

    h6 {
      max-width: none;
      @include font-builder(20px, 600, 23px);
    }
  }

  h6 {
    width: auto;

    &,
    .name {
      @include typography();
    }
  }

  .tooltip-title {
    z-index: $header_z_index;
    cursor: default;
  }

  &.logout-link {
    flex-direction: row !important;
    justify-content: space-between;
  }
}
