@import '../../../assets/style/base/colorsTheme.scss';
@import '../../../style/globalStyles.scss';

.advanced-filter {
	font-size: 0.875rem;
	overflow: hidden;

	.filter-field {
		margin-right: 10px;
		align-items: center;
		display: flex;
		height: 40px;
		justify-content: space-between;
	}

	&__section {
		&,
		> div {
			width: 100%;
		}

		&:has(+ div) {
			width: auto;
		}
	}

	.solution-feature-modal-label {
		@include breakpoint(md) {
			margin-left: -10px;
		}

		input + svg {
			width: 16px;
			height: 16px;
		}

		span {
			& + span {
				margin-top: 5px !important;
				font-weight: 400;
			}

			&:hover {
				cursor: pointer;
			}
		}
	}

	.filter-fields--widget--text-field-box {
		border-radius: 8px !important;

		&.disabled {
			opacity: 0.8;
		}
	}

	.filter-fields--widget--dropdown-field {
		margin-top: 0;
	}

	.text_field {
		position: relative;
		width: auto;
		margin: 0 50px 0 0 !important;

		&__error {
			position: absolute;
			bottom: -3px;
			white-space: nowrap;
		}

		> div {
			&:has(+ .input-container input) {
				position: absolute;
				width: 100px !important;
				right: -105px !important;
				top: 5px;

				> div {
					width: 100%;
				}
			}
		}
	}

	.advanced-geometry-filter {
		> p {
			margin-left: -10px;
			@include flex(center, center);
		}

		.details-popup-target  {
			&:hover {
				cursor: pointer;
			}
		}
	}

	.customize-solution-filter__threads,
	&__bordered {
		max-height: 456px;
		height: 50vh;
		overflow-y: auto;
		overflow-x: hidden;
		border-radius: 10px;
		background: rgba(216, 216, 216, 0.37);
		min-width: 440px;

		@include breakpoint(1680px) {
			min-width: 860px;
		}
		@include styled-scroll();
	}

	.customize-solution-filter__threads {
		margin-top: 0;
		margin-left: 10px;
		font-weight: 400;
		width: 100%;
		min-width: 310px !important;
		min-width: 0;
		padding: 20px 5px 20px 20px;

		.title {
			width: 100px;
			+ div {
				font-size: 0.875rem;
				margin-right: 10px;
				min-width: 165px;
			}
		}
	}

	.solution-feature-modal-explanation-text {
		p {
			width: 60%;
			margin: 10px 0 20px 0;
		}
	}

	&__wrapper {
		margin: 0 15px 0 0;
		padding: 10px;
		display: grid;
		grid-gap: 20px;
		align-items: stretch;
		position: relative;
		row-gap: 16px;

		@include breakpoint(1480px) {
			padding: 20px 5px 20px 20px;
			grid-template-columns: repeat(2, minmax(400px, 1fr));
		}
	}

	.filter-field {
		@include flex(center, space-between);

		label,
		span {
			margin: 0 !important;
		}

		label:first-of-type {
			margin-right: 10px !important;
		}

		label + label {
			&:not(.filter-fields--widget--dropdown-field) {
				margin-right: 35px !important;
				position: relative;

				div + span {
					position: absolute;
					right: -14px;
					width: 14px;
				}
			}
		}
	}
	.text_field__error {
		&.select-error {
			left: -15px;
		}
	}
}

.select-section-title {
	font-size: 14px;
	font-weight: bold;
	padding-left: 12%;
}

.select-section-divider {
	opacity: unset;
	margin: 5px 10px 10px 10px !important;
}

//old styles
.select-toggle {
	&-custom {
		&[class*='selected'] {
			background: #f1f1f1 !important;
		}

		&:hover {
			background: lighten($lightBlue, 40) !important;

			.select-toggle-only {
				opacity: 1;
			}
		}

		&-label {
			padding: 0 !important;
		}
	}

	&-all {
		min-width: 225px !important;
		padding: 0 !important;
		margin: 5px !important;
		height: 35px !important;

		&:hover {
			background: transparent !important;
		}

		button {
			height: 35px;
			width: 225px;
			padding: 5px 15px !important;

			&:hover {
				background: darken($lightBlue, 5);
			}
		}
		&--button {
			margin: 0 8px !important;
			padding: unset !important;
			width: -webkit-fill-available !important;
		}
	}

	&-only {
		margin: 0 2px;
		opacity: 0;
		font-size: 10px;
		font-weight: 600;
		letter-spacing: 0.5px;
		background: #f6fafe;
		padding: 2px 10px;
		border-radius: 6px;
		color: darken($lightBlue, 5);
		transition: all 0.2s ease;
	}
}

.filter-fields {
	&--wrapper {
		width: 100%;
	}

	&--widget {
		&--toggle {
			margin: 16px 0 0;
			width: 100%;
			position: relative;
			text-align: left;

			&--label {
				&#{&} {
					color: $text-regular-opac;
				}
			}
		}

		&--field {
			width: 100%;
			font-size: 18px;
		}

		&--text-field {
			margin: 21px 0 0;

			&-box {
				width: 95px !important;
				height: 40px !important;
				@include round-input();

				&.full {
					width: 100% !important;
					height: inherit !important;
					max-width: 100% !important;
				}
			}
		}

		&--dropdown-field {
			display: flex !important;
			flex-direction: column-reverse;
			margin-top: 21px;
			align-items: flex-start !important;
			margin-left: 0 !important;

			.select {
				@include round-input();
				width: 145px !important;
				height: 40px !important;
				max-width: none;
				margin-left: -20px;
				position: relative;
				font-size: 14px;
				padding: 3px 15px;
				text-overflow: ellipsis;
				box-shadow: none;

				div {
					text-transform: capitalize;
				}
			}

			.text_field__label {
				font-size: 16px;
				color: #848484;
				text-align: left;
				margin-bottom: 10px;
			}
		}

		&--remove {
			&--button {
				&#{&} {
					margin: 16px 0 0 0;
					padding: 0;
					color: $text-regular-opac;
				}
			}

			&--icon {
				color: $text-regular-opac;
				width: 20px !important;
				height: 20px !important;
			}
		}
	}
}
