@import '../../../../../style/globalStyles.scss';

.tm-material {
	&--wrapper {
		@include flex(flex-start, center, column);
		padding: 0 10px;
		.customize-user-material--table-wrapper {
      width: 75%;
      padding: 0 !important;
      @media screen and (max-width: 1250px) {
        min-width: 100%;
      }
		}
	}
	&--add-new {
		@include flex(center, flex-start, row);
		&--button {
			text-transform: uppercase !important;
			cursor: pointer;
			.add-circle {
				width: 16px;
				height: 16px;
			}
		}
	}
	&--form-wrapper {
		width: 75%;
		text-align: left;
		@media screen and (max-width: 1250px) {
			min-width: 100%;
		}
	}
}
