@import '../../../../../style/globalStyles.scss';

.admin {
	&--users {
		&--user-info {
			width: 50%;
			min-width: 600px;
			grid-column-gap: 0 !important;
			column-gap: 0 !important;

			&--field {
				max-width: unset !important;

				&--title {
					font-weight: bold;
					text-transform: capitalize;
				}
			}
		}
		&--icon-loader {
			display: inline-block;
			margin-top: 4px;
		}
	}
}

.admin-user-permission {
	font-weight: bold;

	.details-popup-target {
		margin-top: -5px;
		margin-left: 5px;

		&:hover {
			cursor: pointer;
		}
	}

	&--organization-wrapper {
		@include flex(center, flex-start, row);
    gap: 15px;
    margin-top: 10px;
    padding: 0 12px;
	}
  &--organization-select {
    width: 217px;
  }
}
