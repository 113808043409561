@import '../../../assets/style/base/colorsTheme.scss';
@import '../../../style/globalStyles.scss';

.items-list {
	display: grid;
	grid-template-columns: auto auto auto auto;
	grid-gap: 5px 10px;

	&--item {
		border-radius: 1px;
		background-color: $listItemBackground;
		font-size: 12px;
		color: $text-regular-opac;
		padding: 0 15px 0 5px;
		position: relative;
		cursor: default;

		&:hover {
			color: $text-regular;
		}

		&--remove {
			cursor: pointer;
			margin-left: 5px;
			font-size: 10px;
			position: absolute;
			right: 5px;
			top: -1px;
		}
	}

	&.popup {
		@include flex(flex-start, center, column);

		.items-list--item {
			padding: 3px;
			width: 100%;
			position: relative;
			background: transparent;
			@include flex(flex-start, flex-start, row);

			&:not(:last-of-type):before {
				content: '';
				position: absolute;
				width: 100%;
				top: 25px;
				height: 1px;
				background-color: $text-regular-opac;
				opacity: 0.4;
			}

			&:hover {
				color: $text-regular-opac;
			}

			path,
			svg {
				fill: $darkTextColor !important;
			}
		}

		.items-list--item--remove {
			position: absolute;
			top: 3px;
			right: -3px;
			&:hover {
				transform: scale(1.1);
			}
		}
	}
}

.pointer {
	height: 100%;
	&,
	.items-list--item {
		&:hover {
			cursor: pointer;
		}
	}
}
