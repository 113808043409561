@import '../../../../assets/style/base/colorsTheme.scss';
@import '../../../../style/globalStyles.scss';

.solution-feature-post-processes {
	&--tog {
		align-items: center;
		margin-inline-start: 10px;
	}

	&--info {
		width: 261px;
		&-data {
			font-size: 16px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.44;
			letter-spacing: normal;
			color: $text-regular-opac;
		}
		&-wrapper {
			display: flex;
		}
	}
}

.customize-post-process {
	&--wrapper {
		padding: 12px;
		display: grid;
		grid-auto-rows: 1fr;
	}
	&--row {
		display: grid;
		width: 100%;
		grid-template-columns: 125px 75px repeat(5, 1fr) 90px;
		margin-top: 20px;
		gap: 7px;
	}
	&--cell {
		@include text-ellipsis();
		@include flex(center, flex-start, row);
		input {
			pointer-events: none !important;
		}
		&.name {
			gap: 5px;
			justify-content: space-between;
			span {
				@include text-ellipsis();
				max-width: 90%;
				text-align: left;
				&.no-wrap {
					white-space: nowrap;
				}
			}
		}
		&.action-button {
			justify-content: center;
		}
		svg {
			width: 18px !important;
		}
	}
	&--header {
		font-weight: 500;
		text-align: left;
		&:last-child {
			justify-self: center;
		}
	}
	&--select-field {
		height: 32px !important;
		background-color: transparent;
		border-bottom: 1px solid $darkTextColor !important;
		text-align: left;
		font-size: 12px;
		text-transform: capitalize;
		color: $darkTextColor;
		padding: 0 !important;
		margin: 0 !important;
		width: 100%;
		min-width: 100%;
		&:before,
		&:after {
			display: none;
		}
		&.disabled {
			opacity: 0.7;
			background-color: rgba(0, 0, 0, 0.08);
		}
	}
	&--select {
		padding: 7px 12px 7px 5px !important;
		font-size: 14px !important;
	}
	&--number-input {
		> div {
			top: 0 !important;
			input {
				padding: 6px 5px !important;
			}
		}
		&.with-dollar {
			position: relative;
			input {
				padding-left: 13px !important;
			}
			&::before {
				display: inline-block !important;
				content: '$';
				position: absolute;
				left: 5px;
				top: 5px;
			}
		}
		&.with-percent {
			position: relative;
			input {
				padding-left: 15px !important;
			}
			&::before {
				display: inline-block !important;
				content: '%';
				position: absolute;
				left: 5px;
				top: 5px;
			}
		}
	}
	&--button {
		height: 30px;
		width: 100%;
	}
	&--function-editor-alert {
		position: relative;
		width: 100%;
		height: 100%;
		&--description {
			@include font-builder(16px, 500);
			margin-bottom: 20px;
		}
	}
}
