@import '../../../assets/style/base/colorsTheme.scss';
@import '../../../style/globalStyles.scss';

.weight-reduction-section {
	&--button {
		&#{&} {
			text-transform: unset;
			margin: 0;
		}

		&--wrapper {
			width: auto;
		}
	}

	&--subheader {
		&--not-requested {
			color: $text-regular;
			display: flex;
			align-items: baseline;
			min-height: 38px;

			span {
				line-height: 32px;
			}
			&.not-requested {
				@include flex(flex-start, center, column);
			}
		}

		&--icon {
			margin-right: 10px;
		}

		&--text {
			white-space: pre-wrap;
		}

		&--update {
			margin: 11px 5px;
			font-size: 14px;
			text-transform: uppercase;
			border-bottom: 1px solid;
			padding-bottom: 3px;

			&--loader {
				// we need important because after build order of styles is changed
				margin: 4.5px 5px !important;
				width: auto !important;
				display: inline-block !important;
			}
		}

		&--input {
			&#{&} {
				text-align: center;
			}

			&.input-error {
				color: #ff0000;
			}

			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
				//hide input number arrows
				display: none;
			}

			&--wrapper {
				width: 30px;

				input {
					padding-top: 5px;
					padding-bottom: 4px;
				}
			}
		}
	}
}

.all-weight-reduction {
	width: 100%;

	&--grid {
		&#{&} {
			width: 100%;
			padding: 15px;
		}

		&--tile {
			padding: 1em;
			margin-bottom: -5px;

			.card {
				box-shadow: 0 0 1px 1px $cardShadow;
			}
		}

		&--size-wrapper {
			min-height: unset;
		}

		.card--footer {
			display: none;
		}

		&--item {
			height: auto !important;

			&--subtitle {
				width: 95%;
				text-align: left;
				position: relative;

				&--link {
					color: $button-primary;

					&:hover {
						color: $button-primary-light;
					}
				}

				.weight-reduction-info-popup {
					.solution-analysis-header-text-icon {
						stroke: none;
					}
				}

				div + div {
					position: absolute;
					right: -25px;
					top: 0;
					width: 30px;
				}
			}
		}
	}

	&--popup {
		display: initial;

		& + .details-popup--contact-us {
			top: 35px !important;
		}
	}
}

.weight-reduction-feature {
	min-height: 160px;
}
