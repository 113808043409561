@import '../../../assets/style/base/colorsTheme.scss';
@import '../../../style/globalStyles.scss';

.push-notification {
	position: relative;
	padding: 10px 20px;

	&_icon {
		position: relative;
		transition: all 120ms ease-in;

		&:hover {
			transform: scale(1.1);
		}
		svg {
			position: relative;
			top: 3px;
		}

		&:hover {
			cursor: pointer;
		}
	}

	&_status {
		&.text {
			top: 2px;
			right: -5px;
			position: absolute;
			color: white;
			background-color: $count-note-color;
			font-weight: bold;
			font-size: 10px;
			border-radius: 100%;
			padding: 2px 5px;
		}
	}

	.status {
		&.warning,
		&.old,
		&.new {
			border: 1px solid white;
			width: 8px;
			height: 8px;
			border-radius: 50%;
			background-color: $regular-opac;
		}

		&.new {
			background-color: $count-note-color;
		}

		&.warning {
			background-color: transparent;

			.details-popup-target {
				display: block;
			}

			svg {
				width: 10px;
				position: relative;
				height: auto;
				top: 0px;
				right: 3px;

				path {
					fill: $warningIcon;
				}
			}
		}
	}

	&_title {
		border-bottom: 1px solid $regular-opac;
		padding: 10px 0;

		p {
			font-size: 18px;
			font-weight: 500;
			margin: 10px 10px 10px 20px;
			line-height: 1.3;
			color: black;
		}

		button {
			padding: 0 10px;
			font-weight: 500;
			font-size: 14px;
			margin-right: 10px;
			top: 2px;
		}
	}

	&_wrapper {
		top: 48px;
		width: 300px;
		right: -20px;
		position: absolute;
		box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
		border: 0;
		margin: 2px 0 0;
		z-index: 1000;
		font-size: 14px;
		border-radius: 3px;
		background-clip: padding-box;
		background-color: #fff;
	}

	&_content {
		padding: 5px 0;
		max-height: 500px;
		overflow: auto;
		@include styled-scroll($background: #f0f0f0);
	}

	&_message {
		text-align: left;
		padding: 10px 15px;
		margin-bottom: 1px;
		border-bottom: 1px solid $regular-opac;
		font-size: 12px;
		transition: all 0.2s ease-in;
		position: relative;

		&:not(.no-hover) {
			&:hover {
				cursor: pointer;
				background-color: $select-box-selected-background;
			}
		}

		.status {
			position: absolute;
			left: 10px;
		}

		.text,
		.title {
			margin-bottom: 5px;
			font-weight: 400;
		}

		.time,
		.text,
		.title {
			margin-left: 15px;
		}

		svg {
			font-size: 14px;
			margin-left: 5px;
			fill: $button-primary;
		}

		p {
			font-size: 16px;
			color: $blackColor;
			width: 90%;
			margin: 0 5px 0 0;
			white-space: nowrap;
			@include text-ellipsis();
			font-weight: 400;
		}

		.time {
			font-weight: 400;
		}

		.text {
			font-size: 14px;
			font-weight: 400;
			max-width: 100%;
			word-break: break-word;

			b {
				font-weight: 500;
				color: #000000;
			}
		}
	}
}
