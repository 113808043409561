@import '../../../../assets/style/base/colorsTheme.scss';
@import '../../../../style/globalStyles.scss';

.customize-part-filtering {
	.react-tagsinput {
		padding: 0;
	}
	.part-analysis-filter-name {
		.analysis-exclude-strings-checkbox {
			@include flex(center, flex-start, row);
			text-align: left;
		}
	}

	.customize-solution-filter__threads {
		margin: 10px;
	}
}
