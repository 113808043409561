@import '../../../assets/style/base/colorsTheme.scss';
@import '../../../style/globalStyles.scss';

.castor-breadcrumbs {
	max-width: 35%;
	min-width: fit-content;

	&#{&} {
		z-index: 1111;
		margin: 0.4rem 2rem 0;
		font-size: 0.8rem;
		color: $text-light-opac;
	}

	&--last {
		color: $text-regular;

		.name {
			max-width: 150px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;

			@include breakpoint(1440px) {
				max-width: 190px;
			}
		}
	}

	&--empty {
		&:hover {
			cursor: default;
		}
	}

	&--link {
		color: $text-light-opac;

		&:hover {
			color: $button-primary-light;
		}

		&.disable {
			&:hover,
			&:focus {
				color: $text-light-opac;
			}

			&:hover {
				cursor: default;
			}
		}
	}

	.tooltip-title {
		&,
		.name {
			max-width: 150px;
			@include typography();
		}
	}
}
