@import '../../../../../style/globalStyles.scss';
@import '../../variables';

.new-upload-project {
	&__quick-upload {
		&--card {
			height: 85%;
			&--content {
				height: 100%;
				width: 100%;
				position: absolute !important;
				top: 0;
				&--wrapper {
					height: 100%;
					display: grid;
					grid-template-rows: 50px auto 77px;
					grid-template-columns: 100%;
					padding-top: 45px;
					> div {
						padding: 0 15px;
					}
					&.without-header {
						padding-top: 0px;
					}
				}
				&--header {
					@include font-builder($basicFont, $largeFontWeight, $basicFontHeight);
					width: 100%;
					display: grid;
					column-gap: 5px;
					align-items: start;
					> div {
						&:first-of-type {
							padding-left: 20px;
						}
					}
					&.hidden {
						visibility: hidden;
					}
					&--cell {
						@include flex(flex-start, flex-start, column);
						> div,
						small {
							@include font-builder(
								$smallFont,
								$mediumFontWeight,
								$smallFontHeight
							);
						}
					}
				}
				&--body {
					@include auto-scroll-with-inner-shadow();
					padding: 12px 15px !important;
					display: grid;
					grid-template-rows: repeat(auto-fill, 60px);
					row-gap: 12px;
					.upload-project-dropzone {
						@include flex(center, flex-start, row);
						gap: 4px;
						border: 1px dashed $whiteColor;
						background: linear-gradient($whiteColor, $whiteColor) padding-box,
							linear-gradient(92.35deg, $darkGrey 1.28%, $darkGrey 98.95%)
								border-box;
						border-radius: 4px;
						width: 100%;
						height: 100%;
						padding: 12px 20px;

						&:hover,
						&.drag-on {
							cursor: pointer;
							border-color: $lightBlue;
							background: repeating-linear-gradient(
								45deg,
								rgba(32, 192, 230, 0.03),
								rgba(32, 192, 230, 0.03) 10px,
								rgba(32, 192, 230, 0.01) 10px,
								rgba(32, 192, 230, 0.01) 20px
							);
						}
						&.empty {
							height: 88px;
						}
						& > div {
							width: 100%;
						}
						& > p {
							margin: 0;
						}
						&__icon {
							width: $quick-upload-icon-size;
							height: $quick-upload-icon-size;
							margin-right: 8px;
							path {
								fill: url(#svg-gradient);
							}
						}
						&--title {
							@include font-builder(
								$basicFont,
								$largeFontWeight,
								$basicFontHeight
							);
						}
						&--explanation {
							color: $darkGrey;
						}
					}
					.file-row {
						border: 1px solid $lightGrey;
						border-radius: 4px;
						padding: 15px 0;
						display: grid;
						column-gap: 5px;
						align-items: center;
						div {
							max-height: 30px;
						}
						&.withError {
							border: 1px solid $warnColor;
							transition: border 0.3s;
						}
						&--root {
							position: relative;
							&::before {
								content: '~';
								position: absolute;
								left: 8px;
								top: 4px;
							}
						}
						&--box {
							@include flex(center, space-between, row);
							margin-left: 20px;
							padding: 8px 12px;
							width: 80%;
							height: 100%;
							> div {
								max-width: 80%;
								@include flex(center, flex-start, row);
								gap: 8px;
							}
							svg {
								position: initial;
							}
						}
						.material-selctors-div {
							margin: 0;
						}
						&--select {
							padding: 7px 12px;
							width: 100%;
							&:focus {
								border-radius: 8px;
							}
						}
						&--select-field {
							height: 30px;
							background-color: transparent;
							border: 1px solid $darkTextColor !important;
							border-radius: 8px;
							text-align: left;
							font-size: 12px;
							text-transform: capitalize;
							color: $darkTextColor;
							padding: 0 !important;
							margin: 0 !important;
							width: 85%;
							min-width: 85%;
							&:before,
							&:after {
								display: none;
							}
						}
						&--input-field {
							padding-left: 12px !important;
							width: 85%;
							min-width: 85%;
							&.with-tilde {
								padding-left: 17px !important;
							}
							> input {
								width: 100% !important;
								min-width: unset;
							}
						}
						&--input-placeholder {
							&::-webkit-input-placeholder {
								color: $darkTextColor;
								opacity: 1;
								font-weight: 300;
							}
						}
						&--clear-indicator {
							display: none;
						}
						.focused {
							width: auto;
						}
						.text {
							@include typography();
							@include font-builder(
								$smallFont,
								$mediumFontWeight,
								$smallFontHeight
							);
						}
						&--standard-cost-input {
							@include flex(center, flex-start, row);
							gap: 2px;
							.file-row--select-field {
								width: 80%;
							}
						}
						&--number-input {
							> div {
								top: 0 !important;
								input {
									padding: 6px 12px !important;
								}
							}
						}
					}
				}
				&--footer {
					display: grid;
					grid-template-columns: auto minmax(auto, 250px) 1fr 1fr auto;
					justify-content: space-between;
					align-items: center;
					gap: 20px;
					max-width: 100%;
					&.without-site {
						grid-template-columns: auto 1fr 1fr auto;
					}
					button {
						height: 50px !important;
						margin-top: 12px !important;
					}
					.box-around {
						padding: 12px 30px;
						height: 30px;
						.text {
							@include text-ellipsis();
							font-size: inherit;
							max-width: 250px !important;
							text-wrap: nowrap;
						}
						svg {
							width: 15px;
							height: 15px;
						}
						.icon-close {
							top: 7px !important;
						}
						.icon-file {
							top: 6px !important;
							left: 10px;
						}
					}
					.new-upload-project--organization-selector {
						.customize-settings--header--organization--select-field {
							height: 30px;
						}
						label {
							font-weight: 300;
							font-size: 14px;
							height: 21px;
						}
						.customize-settings--header--organization--select {
							&:focus {
								background-color: transparent;
							}
						}
					}
				}
			}
		}
		&--footer {
			@include font-builder($largeFont, $largeFontWeight, $largeFontHeight);
			padding: 15px;
		}
		&--notes {
			@include flex(flex-start, flex-start, column);
			.label {
				white-space: nowrap;
			}

			&--field {
				max-height: 30px;
				padding: 0 12px !important;
			}
			input {
				padding-top: 7px !important;
			}
		}
		&--start-upload {
			border-radius: 8px !important;
		}
		&--add-bom-file {
			&#{&} {
				background-color: $button-secondary;
				border: 1px solid $button-border-color;
				border-radius: $button-border-radius;

				span {
					color: $lightBlue;
				}
			}

			&-label {
				color: $text-regular-opac;
			}
		}
		&--warn-message {
			color: $warnColor;
			margin: 0;
			text-align: left;
			svg {
				path {
					fill: $warnColor;
				}
			}
		}
		&--add-bom-file-modal {
			@include flex(center, center, column);
			gap: 20px;
			.bom-file-upload__text {
				margin-right: 5px;
			}
			.bom-file-upload {
				> div {
					width: auto !important;
				}
			}
			.icon-close,
			.icon-file {
				top: 9px !important;
			}
			&--body {
				padding-left: 26px;
				padding-right: 26px;
				.bom-file-upload {
					.text {
						max-width: 200px !important;
					}
				}
			}
		}
	}
}

.file-material-selector--box {
	&.select-box {
		@include font-builder($smallFont, $mediumFontWeight, $basicFontHeight);
		padding: 8px 24px;
		text-transform: capitalize;
	}
}

.file-row {
	&--option {
		&::before {
			content: '~';
		}
	}
	&--no-options {
		display: none;
	}
}
