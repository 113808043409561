.castor-icon {
	&:not(&-small) {
		width: 171px;
	}
}

.benefit-icon-wrapper {
	margin: 0.1em;
	z-index: 3 !important;
	display: flex;

	&:last-of-type {
		margin-right: 0;
	}
}

.details-popup-data-benefits-message {
	top: -5px !important;

	div {
		span {
			text-transform: capitalize;
		}
	}
}

.grid-list-benefits {
	.benefit-icon-wrapper {
		margin-right: 8px;
		min-width: 20px;

		&:last-of-type {
			margin-right: 0;
		}
	}
}

.benefit-section {
	.benefit-name {
		text-transform: uppercase;
	}
}
