@import '../../../../assets/style/base/colorsTheme.scss';
@import '../../../../assets/style/base/designTheme.scss';
@import '../../../../style/globalStyles.scss';

.step-card {
	margin: 0 !important;
	height: 100%;
	max-height: 100%;
	> div {
		padding: 0 !important;
		height: 100%;
		max-height: 100%;
		min-height: 100%;
		display: grid;
		grid-template-rows: auto 1fr auto;
	}
	&.body-full-height {
		> div {
			grid-template-rows: auto;
		}
	}
	&.with-background {
		.step-card--body {
			background: $onboardingBackground;
		}
	}
	&.without-header {
		> div {
			grid-template-rows: 1fr auto;
		}
	}
	&--header {
		@include flex(center, space-between, row);
		background: $stepCardHeaderBackground;
		padding: 24px 20px;
		margin: 0;
		text-align: left;
		gap: 5px;
		h4 {
			@include font-builder(20px, 500);
			margin: inherit;
		}
		p {
			margin: inherit;
		}
		&--selected-organization {
			@include flex(flex-end, flex-start, row);
			height: 100%;
			gap: 7px;
			text-wrap: nowrap;
		}
		.with-organization {
			max-width: 60%
		}
	}
	&--body {
		@include auto-scroll-with-inner-shadow();
		padding: 24px 10px;
		hr {
			margin: 20px 10px !important;
		}

		.sticky-bottom {
			bottom: -25px;
			padding: 10px 20px;
		}
	}
	&--footer {
		@include flex(center, space-between, row);
		height: 72px;
		padding: 20px;
		&--button {
			text-transform: uppercase !important;
			cursor: pointer !important;
		}
		&--continue-buttons {
			@include flex(center, flex-start, row);
			gap: 20px;
		}
	}
}
