@import '../../../assets/style/base/colorsTheme.scss';
@import '../../../assets/style/base/designTheme.scss';
@import '../../../style/globalStyles.scss';

.page-not-exist {
	@include flex(center, center, column);
	height: 100vh;
	width: 100%;
	position: absolute;
	z-index: -1;
	top: 0;

	&.only-message {
		z-index: 1;

		p {
			font-size: 18px;
		}
	}

	svg {
		display: none;
		width: 50%;
		height: 50%;
	}

	&.show {
		svg {
			display: block;
		}
	}

	#main-color {
		fill: $lightBlue !important;
	}

	.small {
		text-align: center;
		font-size: 14px;
	}
}

.App:has(#sidebarWrapper) .page-not-exist {
	.page-not-exist__button,
	svg {
		display: none;
	}

	.text {
		display: block;
		margin-left: 100px;
	}
}
