@import '../../../../assets/style/base/colorsTheme.scss';
@import '../../../../assets/style/base/designTheme.scss';
@import '../../../../style/globalStyles.scss';

.custom-material-names {
  &--divider {
    opacity: unset;
    margin-top: 5px !important;
    margin-left: 5px !important;
    width: 98%;
  }

  &--add-material-text {
    width: 335px;
    margin-right: 20px;
  }

  &.text {
    min-width: 280px;
    padding-right: 20px;
    margin-left: 15px;
  }

  &--title {
    color: #565656;
    margin-top: 20px;
    font-size: 16px;
    font-weight: normal;
    padding-top: inherit;
    margin-bottom: 7px;
    font-weight: 500;
    padding-left: 13px;
  }

  &--explanation {
    @include flex(center, space-between);
    color: #797878;
    margin-bottom: 7px;
    padding-left: 13px;
    margin-right: 20px;
    width: 840px;
    text-align: left;

    button {
      margin-left: 10px;
    }
  }

  &--button {
    color: white;
  }

  &--AddButton {
    color: white;
  }
}

.castor-alert {
  &.add-multiple-materials {
    width: 660px !important;
  }

  .bom-file-upload {
    width: 100%;

    > div {
      justify-content: space-between;
      width: 100%;
    }
  }

  .box-around {
    .text {
      font-size: 12px;
    }
  }
}
