@import '../../../assets/style/base/colorsTheme.scss';
@import '../../../style/globalStyles.scss';

.user-activity-data {
	font-size: 17px;

	&--general {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: repeat(2, 1fr);

		&-detail {
			margin-bottom: 15px;
			margin-right: 5px;
			height: max-content;
		}

		&--product {
			font-weight: bold;
			text-transform: capitalize;
		}
	}

	&--upgrade {
		@include flexible-row;
		width: 90%;

		&--product {
			@include flexible-row;

			&--text {
				font-weight: bold;
				margin: 10px;
			}
		}

		&--button {
			&#{&} {
				text-transform: unset;
				padding: 10px 20px;
				font-size: 17px;
			}

			&.upgrade {
				margin-right: 10px;
			}
		}
	}
}
