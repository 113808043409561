@import '../../../../assets/style/base/colorsTheme.scss';

.custom-calculation-co2 {
	&--title {
		color: #565656;
		margin-top: 20px;
		font-size: 16px;
		font-weight: normal;
		padding-top: inherit;
		margin-bottom: 7px;
		font-weight: 500;
		padding-left: 13px;

		&.big {
			font-size: 20px;
		}
	}

	&--wrapper {
		width: 100% !important;
		align-items: center;
		justify-content: space-between;
		padding: 0 13px;
		button {
			width: 'unset';
			max-width: 70px !important;
			height: 33px !important;
			&.button-to-default {
				max-width: 130px !important;
				margin-right: 10px;
				margin-left: 10px;
			}
		}
	}

	&--prime-title {
		color: $text-regular-opac;
		margin-top: 20px;
		font-size: 16px;
		font-weight: normal;
		padding-top: inherit;
		margin-bottom: 7px;
		font-weight: 400;
		padding-left: 13px;
	}
	&--divider {
		opacity: unset;
		margin-top: 35px !important;
		margin-left: 15px !important;
		margin-bottom: 35px !important;

		width: 98%;
	}
	&--error {
		font-size: 0.9rem;
		align-self: center;
		color: red;
		padding-left: 13px;
	}
	&--explanation {
		padding-top: 25px;
		padding-left: 13px;
		color: #797878;
		font-size: 16px;
		font-weight: 300;
		&-text {
			color: #797878;
			font-size: 16px;
			font-weight: 300;
		}
	}
	&--container {
		margin-left: 15px;
		margin-bottom: 8px;
	}
	&--table {
		&--icon {
			&#{&} {
				width: 17px;
				height: 17px;
			}
		}
	}
	&--material-picker {
		width: 70%;
		&--text {
			margin-right: 1rem;
			margin-left: 1rem;
			width: 8.5rem;
		}
		&--select {
			margin-right: 0.5rem;
			margin-left: -3.5rem;
			width: 18rem;
		}
	}

	&--printer-picker {
		width: 50%;
		&--text {
			margin-right: 1rem;
			margin-left: 1rem;
			width: 8.5rem;
		}
		&--select {
			margin-right: 0.5rem;
			margin-left: -3.5rem;
			width: 18rem;
		}
	}

	&--carbon-picker {
		width: 17%;
	}

	&--product-use-picker {
		width: 7%;
	}

	&--hold-picker {
		width: 10%;
	}

	&--carbon-picker,
	&--end-of-life-picker {
		width: 6%;
		min-width: 40px !important;
	}

	&--carbon-picker {
		width: 15%;
	}
	&--twh-picker {
		width: 30%;
	}

	&--icon-info {
		margin-left: 10px;
		margin-top: 17px;
		&-content {
			width: 265px;
			background-color: $tooltip-background-color;
		}
	}

	&--select {
		margin-right: 1rem;
		width: 150px;
	}
}

.carbon-credit-section {
	.material-production--wrapper {
		width: 100%;
	}
}

.printers-selector {
	display: flex;
	margin: 0.6em;
	justify-content: space-between;
	align-items: center;
	margin-left: 2px;

	.select {
		margin-right: 1rem;
		width: 160px;
	}
}

.cycle-selector {
	display: flex !important;
	margin: 0.6em;
	justify-content: space-between;
	align-items: center;
	margin-left: 1px;
	.select {
		margin-right: 0.6rem;
		width: 180px;
	}
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield;
}

.return-to-default {
	position: absolute;
	right: -10px;
	bottom: -30px;

	button {
		padding: 0;
		margin: 0;
	}
}

.co2-technology-field {
	min-width: 190px !important;
}
