@import '../../../assets/style/base/colorsTheme.scss';
@import '../../../style/globalStyles.scss';

.admin-home-page {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.admin-multiple-line-field {
	display: flex;
	flex-direction: column;
}

.admin-multiple-line-elypsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.admin-table-buttons-div {
	width: 7em;
}

.admin-table-button {
	margin: 2px 1px !important;
}

.admin-form {
	margin: 1em;
}

.admin-form.inline {
	display: flex;
	align-items: center;
	order: 1;
}

.admin-form-field {
	width: 100%;
	margin: 0.6em 0 !important;

	&--flex {
		display: flex;
		align-items: flex-end;

		&.with-required {
			align-items: center;

			.admin-form-field__info_icon {
				margin: 0 0 0 20px;
			}
		}
	}

	&__info_icon {
		margin: 0 0 5px 20px;
	}

	&--maintenance-div {
		margin: 10px 0;
		display: flex;
		align-items: center;

		&-selector {
			&--root {
				&#{&} {
					width: unset;
				}
			}
		}
	}

	&--machineCost-div {
		margin: 10px 0;
		display: flex;
		align-items: center;
		width: 100%;

		&-selector {
			&--root {
				&#{&} {
					width: unset;
				}
			}
		}
	}
}
.admin-form-field-maintenance-machine-cost {
	width: 400px;
}
.admin-form-field-machine-cost {
	width: 400px;
}
.admin-form-field.inline {
	padding-right: 0.5em;
	margin-top: 0 !important;
}

.form-multiple-fields {
	display: flex;
}

.form-field-in-multiple {
	width: 100%;
}

.form-field-toggle {
	display: flex;
	align-items: center;
}

.form-field-text-custom {
	margin: 5px 0px;
}

.form-field-in-multiple.first {
	margin-right: 1em;
	width: 70%;
}

.form-field-in-multiple.last {
	margin-right: 1em;
	width: 30%;
}

.admin-table-linkable-header {
	cursor: pointer;
	display: flex;

	&--icon {
		stroke: #999999;
		width: 16px;
		height: 16px;
		margin-right: 3px;
	}

	&--sorted {
		color: #222222;

		.admin-table-linkable-header--icon {
			stroke: #222222;
		}
	}
}

.admin {
	&--projects {
		&--project-info {
			min-width: 600px;

			&--field {
				max-width: unset !important;

				&.overflow-text {
					max-width: 350px !important;
				}

				&--title {
					font-weight: bold;
					text-transform: capitalize;
					max-width: unset !important;
				}
			}
		}

		&--icon-loader {
			display: inline-block;
			margin-top: 4px;
		}
	}
}

.admin-buttons-wrapper {
	@media screen and (max-width: 1500px) {
		flex-direction: column;
	}
}

.values-with-deviation {
	> div {
		width: 50%;
	}
}

.values-with-deviation,
.deviation {
	div + p {
		position: absolute;
		bottom: -18px;
	}
}
.note-muted {
	margin-top: 15px;
}
.flex-block {
	@include flexible-row();

	.details-popup-target {
		margin-top: 15px;
	}

	&.start {
		justify-content: flex-start;
		align-items: flex-end;
	}
}

.checkboxes-block {
	p {
		font-size: 16px;
		margin: 10px 0 0;
	}

	.special-materials {
		display: grid;
		grid-template-columns: repeat(3, minmax(150px, 1fr));
		grid-gap: 20px;
		align-items: stretch;
	}
}

.values-with-deviation {
	@include flexible-row();

	> div {
		&:first-of-type {
			margin-right: 10px !important;
		}

		&:last-of-type {
			margin-left: 10px !important;
		}
	}
}

.deviation {
	display: flex;
	flex-wrap: wrap;

	& > div {
		flex-basis: 275px;
		flex-shrink: 0;
	}

	.block-with-deviation {
		margin: 10px;

		&__flex {
			@include flexible-column();
			align-items: flex-start;

			input {
				padding: 5px 10px;
			}
		}

		&--error {
			bottom: -18px;
		}

		.label {
			color: rgba(0, 0, 0, 0.54);
			padding: 0;
			font-size: 1rem;
			margin-left: -10px;
			margin-bottom: 10px;

			div {
				width: 100%;

				&:before {
					background-image: none !important;
				}
			}
		}

		.value-label {
			margin-right: 10px;
		}

		&__item {
			@include flexible-row();
			position: relative;
			right: 5px;

			&:last-of-type {
				&:after {
					display: none;
				}
			}

			.admin-form-field {
				width: 100px;
				margin-top: 0 !important; //override mui
				margin-right: 10px !important; //override mui

				&:last-of-type {
					margin-left: 10px !important; //override mui
				}
			}
		}
	}
}

.standard-block-with-deviation {
	.label {
		margin-top: 10px;
		color: rgba(0, 0, 0, 0.54);
		padding: 0;
		font-size: 1rem;
	}

	.flex-block {
		display: flex;
		flex-wrap: wrap;

		input {
			padding: 5px 10px;
		}

		& > div {
			margin: 10px 10px 10px 0 !important;
			flex-basis: 195px;
			flex-shrink: 0;
		}
	}
}

.table-loader {
	top: 25px !important;
	z-index: 0 !important;
	width: calc(100% - 40px) !important;
}

.routes {
	overflow: hidden !important;
	height: 100vh;
	&.with-banner {
		height: calc(100vh - 64px);
	}
}

.fade-appear,
.fade-enter {
	.main-content-scroller {
		opacity: 0;
		z-index: 1;
	}
}

.fade-appear-active,
.fade-enter.fade-enter-active {
	.main-content-scroller {
		opacity: 1;
		transition: opacity 350ms linear 250ms;
	}
}

.fade-exit {
	.main-content-scroller {
		opacity: 1;
	}
}

.fade-exit.fade-exit-active {
	.main-content-scroller {
		opacity: 0;
		transition: opacity 250ms linear;
	}
}
