@import '../../../assets/style/base/colorsTheme.scss';
@import '../../../style/globalStyles.scss';

.stats-clac-according {
	@include flexible-row;
	justify-content: unset;

	&--explanation {
		color: $text-regular-opac;
		font-size: 18px;

		&--select {
			flex: none;
			margin-left: 20px;

			&--item {
				&#{&} {
					width: auto;
					padding-right: 45px;
					font-size: 16px;
					line-height: 39px;
				}

				&-menu {
					text-transform: capitalize;
				}
			}
		}
	}
}
