@import '../../../../../style/globalStyles.scss';

.cost-and-supply-chain {
	&--wrapper {
		gap: 30px;
		display: grid;
		grid-template-rows: auto;
		h3 {
			margin-top: 0 !important;
		}
		.customize-post-process--wrapper {
			padding: 12px 22px;
		}
		.customize-post-process--row {
			grid-template-columns: 200px 75px repeat(5, 1fr) 100px;
		}
	}
}
