.admin-form-with-info {
  align-items: unset;

  .details-popup-target {
    position: relative;
    top: 25px;
    margin: 0;

    .info-box-icon {
      margin-bottom: 0;
    }
  }
}