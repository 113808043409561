@import '../../../../../assets/style/base/colorsTheme.scss';
@import '../../../../../assets/style/base/designTheme.scss';
@import '../../../../../style/globalStyles.scss';
@import '../../variables';

.new-upload-project {
	.main-content-scroller {
		@include flex(center, initial);
	}

	&__materials {
		display: flex;
		flex-direction: column !important;

		&.disabled {
			opacity: 0.7;

			&:hover {
				cursor: no-drop;
			}

			label {
				pointer-events: none;
			}
		}

		label {
			margin: 0 0 8px;

			@include breakpoint(custom, 1960px, 1080px) {
				margin: 0 0 12px;
			}

			p {
				margin: 0;
			}
		}

		.upload-form-radio-bom {
			padding-top: 0;
			display: flex;
			flex-direction: column;
		}

		.upload-form-radio {
			padding: 0;
			width: 14px;
			height: 14px;
			margin-right: 8px;

			svg {
				font-size: 14px;
			}
		}
	}

	&__content {
		&.without-project-name {
			max-height: 245px;

			.upload-form__scenario {
				margin-top: 0;
			}
		}

		&.with-project-name {
			max-height: 375px;

			.field-with-label__block {
				margin-bottom: 10px;
			}
		}

		&-material {
			min-height: 175px;

			@include breakpoint(custom, 1960px, 1080px) {
				min-height: 180px;
			}
		}

		&-advanced {
			margin: 10px 0 10px;

			@include breakpoint(custom, 1960px, 1080px) {
				margin: 12px 0 12px;
			}

			&,
			& > div {
				height: 35px;
			}

			.upload-project-filters-button {
				font-size: $basicFont;
				line-height: $basicFontHeight;
				text-transform: uppercase;
				text-decoration: underline;
				padding: 0;
				margin: 15px 15px 0 0;
				border: 0;
			}

			.items-list {
				&--item {
					background: $whiteBackground;
					border: 1px solid $darkGrey;
					border-radius: 8px;
					padding: 4px 12px;
					@include flex(center);
					@include font-builder(
						$smallFont,
						$mediumFontWeight,
						$smallFontHeight
					);

					&--remove {
						right: initial;
						top: 1px;
						position: relative;

						.icon-close {
							&,
							path {
								fill: $darkTextColor;
							}
						}
					}
				}
			}
		}

		&-tolerance {
			.tolerance-check-box {
				height: 25px;
				margin-left: -15px;

				@include breakpoint(custom, 1960px, 1080px) {
					height: 40px;
				}

				&_items {
					.select-box {
						margin: 0 10px;
						text-transform: capitalize;
						@include font-builder(
							$basicFont,
							$mediumFontWeight,
							$basicFontHeight
						);

						&:first-of-type {
							margin-left: 0;
						}

						&:last-of-type {
							margin-right: 0;
						}
					}
				}
			}
		}
	}

	.beta-tag {
		@include flex(center, center);
		@include font-builder(
			$smallFont - 2px,
			$mediumFontWeight,
			$smallFontHeight - 2px
		);
		top: 11px;
		left: -1px;
		width: 40px;
		height: 16px;
		transform: matrix(0.7, -0.7, 0.73, 0.7, 0, 0);
		color: #ffffff;
		background: $darkGrey;
		text-transform: uppercase;
		position: absolute;
		box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

		&:before,
		&:after {
			position: absolute;
			content: '';
			width: 0;
			height: 0;
			top: 0;
			border-style: solid;
		}

		&:before {
			left: -16px;
			border-width: 0 0 16px 17px;
			border-color: transparent transparent $darkGrey transparent;
		}

		&:after {
			right: -16px;
			border-width: 16px 0 0 17px;
			border-color: transparent transparent transparent $darkGrey;
		}

		&.active {
			background: $lightBlue;

			&:after {
				border-color: transparent transparent transparent $lightBlue;
			}

			&:before {
				border-color: transparent transparent $lightBlue transparent;
			}
		}
	}

	.upload-form-learn-how-button {
		@include font-builder($basicFont, $mediumFontWeight, $basicFontHeight);
		text-decoration-line: underline;
		text-transform: uppercase;
		margin-left: 5px;
		color: $lightBlue;
	}

	.bom-file-upload {
		margin-top: 5px;
		height: 40px;

		@include breakpoint(custom, 1960px, 1080px) {
			margin-top: 12px;
		}

		.select-box {
			padding: 8px 29px;
			margin-left: 0;
		}

		.transparent-button,
		.bom-file-upload__button,
		.bom-file-upload__text {
			@include font-builder($basicFont, $mediumFontWeight, $basicFontHeight);
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.transparent-button {
			font-weight: 400;
			text-transform: uppercase;
			border-bottom: 1px solid $lightBlue;

			svg {
				margin-right: 5px;
			}
		}

		.box-around {
			cursor: initial;

			.text {
				max-width: 280px;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				user-select: none;
			}

			.icon-file {
				top: 10px;
				left: 10px;
			}

			.icon-close {
				top: 10px;
				right: 10px;
				cursor: pointer;
			}
		}

		.bom-file-upload__button {
			font-size: 12px;
			padding: 10px 16px;
			background: $lightBlue;
			border-radius: 8px;
			border: 1px solid $lightBlue;
			color: $whiteColor;
			text-transform: uppercase;
			margin: 0;

			@include breakpoint(custom, 1960px, 1080px) {
				font-size: 14px;
				padding: 12px 24px;
			}

			&:hover {
				box-shadow: 0 3px 3px $hoverColor;
			}
		}
	}

	.warn-message {
		@include font-builder($basicFont, $mediumFontWeight, $basicFontHeight);

		&.red {
			color: $warnColor;

			svg {
				&,
				path {
					fill: $warnColor;
				}
			}
		}
	}

	.field-with-label {
		&[class*='disabled'] {
			opacity: 0.5;
		}
	}
}

.advanced-filters {
	&.castor-alert {
		&.full-screen {
			left: 20% !important;
			top: 15vh !important;
			width: calc(100% - 645px) !important;
			max-width: 1250px !important;
			min-width: 815px;

			@include breakpoint(1480px) {
				width: calc(100% - 355px) !important;
			}
		}
	}
	.castor-alert--body {
		&.full-screen {
			height: auto;
			padding: 15px;
		}
	}
}
