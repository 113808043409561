@import '../../../../../assets/style/base/colorsTheme.scss';
@import '../../../../../assets/style/base/designTheme.scss';

.smtp-form {
  width: 100%;
  min-height: 440px;

  &__field {
    margin: 15px 0;

    > div {
      width: 50%;
    }
  }

  .button-block {
    width: 100%;

    button {
      margin-left: 20px;
    }
  }

  .icon {
    &-fail,
    &-success {
      width: 10px;
      height: 10px;
      border-radius: 100%;
      margin-right: 5px;
    }

    &-fail {
      background-color: $warnColor;
    }

    &-success {
      background-color: $successText;
    }
  }
}