@import '../../../../assets/style/base/colorsTheme.scss';
@import '../../../../style/globalStyles.scss';

.multiple-projects {
	&__form {
		width: 65%;

		.menu-item {
			width: 250px;
			@include flex(space-between, center);

			.menu-text {
				width: 200px;
				@include text-ellipsis();
			}
		}
	}

	&__selected-value {
		@include text-ellipsis();
	}

	.error {
		position: absolute;
		bottom: 0;
		color: $warnColor;
	}

	.info-popup {
		@include flex();
		> div {
			align-self: center;
		}
	}
}

.multiple-projects-select-paper {
	width: 250px;
	height: 550px;
	padding-bottom: 0;

	ul {
		padding: 0;
	}

	.multiple-projects-select-paper-button {
		@include flex(center, space-between);
		position: sticky;
		background-color: white;
		width: 100%;
		height: 60px;
		top: 0;
		padding: 5px 10px 5px 20px;
		z-index: 1;

		button {
			margin: 0;
		}

		.multiple-projects-select-paper-count {
			span {
				font-weight: bold;
			}
		}
	}

	.multiple-projects__select-only {
		font-size: 12px;
		font-weight: 600;
		letter-spacing: 0.5px;
		background-color: #f6fafe;
		padding: 2px 10px;
	}
}
.recalculate-projects {
	&--organization-selector {
		@include flex(center, flex-start, row);
		gap: 10px;
		margin-left: 27px;
		.customize-settings--header--organization--select {
			font-weight: 400;
		}
	}
	&--organization--select-field {
		width: 250px;
	}
	&--organization-checkbox {
		padding: 0 !important;
	}
}
.multiple-projects-select-only {
	min-height: 172px;
}
