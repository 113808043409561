@import '../../../assets/style/base/colorsTheme.scss';
@import '../../../style/globalStyles.scss';

.profile {
	&--select-field {
		width: 100%;

		> div {
			font-size: 16px !important;
			font-weight: 400 !important;
			padding-left: 0 !important;
		}
		&--label {
			transform: scale(0.75);
			font-size: 16px !important;
			margin-top: 27px;
      margin-bottom: -4px;
			color: rgba(0, 0, 0, 0.54) !important;
		}
	}
}
