@import '../../../../../assets/style/base/colorsTheme.scss';
@import '../../../../../assets/style/base/designTheme.scss';

.reset-server {
	width: 100%;

	&__field {
		margin: 15px 0;

		> div {
			width: 50%;
		}
	}

	.button-block {
		width: 100%;

		button {
			margin-left: 20px;
		}
	}

	&-form {
		font-weight: 400;

		.label {
			font-size: 12px;
			opacity: 0.7;
		}

		.select {
			&.disabled {
				> div {
					font-weight: 400;
					opacity: 0.4;
				}
			}
		}
	}
}

.dropdown.my-dropdown-menu {
	ul {
		height: 500px;
		overflow: auto;

		li {
			padding: 10px 20px !important;
		}
	}
}
