@import '../../../assets/style/base/designTheme.scss';
@import '../../../assets/style/base/colorsTheme.scss';
@import '../../../style/globalStyles.scss';

.customize-form {
	width: 97%;
	height: auto;
	margin: 5%;
	padding: 16px 0;

	&--divider {
		margin-top: 1rm;
	}
}

.select-btn {
	height: 48px;
	background-color: #20c0e6;
	border: 2px solid #20c0e6 !important;
	border-radius: 3px;
	text-align: center;
	font-size: 17px !important;
	font-weight: bold;
	text-transform: capitalize;
	transition: all 0.3s ease-out;
	padding: 4px 10px;
	margin-top: 12px;
	width: 188px;
	border-color: #20c0e6;
	color: #ffffff !important;

	&::before {
		border-bottom: none !important;
	}

	&::after {
		border-bottom: none !important;
	}
}

.material-picker {
	width: 90%;

	&--currency-sign {
		font-size: 1rem;
	}

	&--text {
		margin-right: 1rem;
		margin-left: 1rem;
		width: 8.5rem;
	}

	&--select {
		margin-right: 0.5rem;
		margin-left: -3.5rem;
		width: 18rem;
	}
}

.custom-placeholder {
	overflow: hidden;
	text-overflow: ellipsis;
	color: $text-regular-opac;
}

.new-material-btn {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.customize-user-btn {
	padding: 5px 20px !important;
	width: 250px;
}

.price-flex {
	align-items: center;
	justify-content: center;
	display: flex;

	.material-picker {
		align-items: center;
		justify-content: flex-start;
		display: flex;
	}
	.small {
		width: 70px;
		margin-left: -5px;
	}
}
.customize-settings {
	&--header {
		@include flex(center, center, column);
		width: auto;
		margin: auto;
		.castor-devider {
			max-width: 876px;
			width: 100%;
			margin: 10px 0;
		}
		@media screen and (max-width: 1200px) {
			width: 100%;
			.form-header-wrapper {
				width: 100% !important;
			}
		}

		&--organization {
			@include flex(center, space-between, row);
			width: 100%;
			padding: 12px;
			font-size: 16px;
			&-name {
				@include flex(center, flex-start, row);
				gap: 7px;
				svg {
					width: 20px;
					height: 20px;
					margin-top: -4px;
					cursor: pointer;
					transition: all 0.3s ease-out;
					path {
						fill: $button-border-color;
					}
					&:hover {
						transform: scale(1.25);
					}
				}
			}
			&-selector {
				@include flex(center, flex-end, row);
				width: auto;
				gap: 5px;
				span {
					text-wrap: nowrap;
				}
				.add-new-organization {
					width: 22px;
					height: 22px;
					cursor: pointer;
					margin-left: 5px;
					transition: all 0.3s ease-out;
					path {
						fill: $button-border-color;
					}
					&:hover {
						transform: scale(1.25);
					}
				}
			}
			&--select {
				padding-left: 5px !important;
				font-weight: 500 !important;
				color: $object-header-background !important;
			}
			&--select-field {
				height: 30px;
				background-color: white;
				border: 1px solid $darkTextColor !important;
				border-radius: 8px;
				text-align: left;
				font-size: 12px;
				color: $darkTextColor;
				padding: 0 !important;
				margin: 0 !important;
				width: 250px;
				&:before,
				&:after {
					display: none;
				}
			}
		}
		&.cost-function-editor {
			margin: 0;
			padding: 0 12px;
			align-items: flex-start;
			.form-header-wrapper {
				width: auto !important;
				align-self: flex-start !important;
			}
			.castor-devider {
				max-width: 100%;
				width: -webkit-fill-available;
				margin: 10px 12px;
			}
		}
	}
}
.edit-organization {
	&--alert-body {
		@include flex(flex-start, flex-start, column);
		width: 100%;
		gap: 10px;
		padding-top: 5px;
		&--name-input {
			width: 100%;
			position: relative;
			label {
				font-size: 16px;
				color: $text-regular-opac;
			}
			> div {
				margin: 0 !important;
			}
		}
		&--private-checkbox {
			@include flex(center, flex-start, row);
			&--info {
				fill: $text-regular-opac;
				stroke: none;
			}
		}
		&--checkbox {
			> span {
				font-size: 16px;
			}
			margin-right: 5px !important;
		}
		&--duplicating-selector {
			@include flex(center, flex-start, row);
			width: 100%;
			gap: 10px;
			.customize-settings--header--organization--select {
				font-weight: 300 !important;
				max-width: 100%;
			}
			.customize-settings--header--organization--select-field {
				width: 100%;
			}
			span {
				text-wrap: nowrap;
				font-size: 16px;
			}
		}
	}
}
