@import '../../../../../../../assets/style/base/colorsTheme.scss';
@import '../../../../../../../assets/style/base/designTheme.scss';

// New table styles
.solution-tab--new-table {
	grid-template-columns: 4fr 1fr 1fr 1fr !important;
	border: 1px solid $tableBorderColor;
	border-radius: 6px;
	padding-bottom: 20px;
	background-color: $tableBackground;
	font-size: $text-field-tab-font-size;
	color: #000000;
	letter-spacing: 0.25px;
	margin-bottom: 30px;

	&.solution-tab--material-table {
		grid-template-columns: 2.3fr 1.3fr 1.3fr 1fr !important;

		&.solution-tab--material-three-column {
			grid-template-columns: 2.3fr 1.3fr 1.3fr !important;
			&.triple-comparison {
				grid-template-columns: 2.3fr 1.3fr 1.3fr 0.7fr !important;
				.data-table-header {
					&:not(.data-table--first-column--inside-row) {
						padding: 10px 5px 10px 0;
					}
				}
			}
		}

		&.solution-tab--material-two-column {
			grid-template-columns: 2.3fr 1.3fr !important;
			&.triple-comparison {
				grid-template-columns: 2.3fr 1.3fr 1.3fr !important;
				.data-table-header {
					&:not(.data-table--first-column--inside-row) {
						padding: 10px 5px 10px 0;
					}
				}
			}
		}
	}

	.data-table-header {
		background-color: $tableHeaderBackground;
		letter-spacing: 0.16px;
		font-weight: 900;
	}

	.data-table-text-field {
		max-width: 100%;
		white-space: pre-wrap;
	}

	.data-table-opacity-field {
		color: #000000;
	}

	.data-table--break-row-line {
		width: calc(100% - 23px);
		margin-left: 23px;
		border: 0; // to avoid prev border
		border-bottom: 1px solid $primaryBorder;
	}

	.content-center {
		margin: auto;
		justify-content: center;
		display: flex;
	}

	.data-table {
		&--inside-row {
			font-size: $text-field-tab-font-size;
		}

		&--last {
			&-column {
				padding-right: 22px !important; //override parent important
			}
		}

		&--first {
			&-row,
			&-column {
				padding-left: 20px !important; //override parent important

				&--inside {
					padding-left: 70px !important; //override parent important

					&.extra-space {
						padding-left: 73px !important; //override parent important
					}
				}

				&.nested-child {
					padding-left: 85px !important; //override parent important
				}

				&.child {
					padding-left: 30px !important; //override parent important
				}
			}
		}

		&-row-icon {
			margin: 0;
			top: 6px;
			left: 5px;
			position: relative;

			&--small {
				width: 20px;
				height: 20px;
				min-height: 20px;
				min-width: 18px;
				cursor: pointer;
			}

			&--with-opacity {
				opacity: 0.3;
				margin: auto;
			}

			&.warning-color {
				color: $warningIcon;
			}
		}
	}

	.cost-summary--table--text {
		.data-table-row-text,
		svg {
			display: inline;
		}

		&--fixed-height {
			height: 48px;
			display: flex;
			align-items: center;
			font-size: $text-field-tab-font-size;
		}

		&--full-detail {
			color: #000000;

			&:not(.data-table--first-column):not(.with-i-icon) {
				width: 80%;
				overflow: visible;
				gap: unset;
				text-align: left;

				&.content-center {
					text-align: center;
				}

				&,
				> div,
				svg {
					display: inline;
				}
			}

			&.success {
				background-color: $successBackground;
				color: $successText;
				font-weight: 600;
			}
		}

		&--title {
			&.success {
				p:first-of-type {
					color: $successText;
				}
			}

			p {
				color: $primaryTableText;
				margin-bottom: 4px;
				font-size: $text-field-tab-font-size;
				line-height: 16px;

				&:first-of-type {
					color: $primaryTableHeader;
					letter-spacing: 0.15px;
					font-size: $text-field-header-font-size;
					line-height: $text-field-header-font-size;
					font-weight: 600;
				}
			}
		}
	}
}

.data-table-results--grid {
	height: $data-table-results-grid-height;
	padding-top: 8px;
	width: 100%;

	.data-table-row-text {
		font-size: $text-field-tab-font-size;
	}

	.data-table-long-text-field {
		font-size: 15px;
	}
}
