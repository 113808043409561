$background: #f1f1f1;
$success: #64c882;
$warning: #f9d82d;
$riskWarning: #2568dd;
$object-header-text: #ffffff;
$object-header-text-focused: #ffffff;
$object-header-background: #383838;
$object-content-background: white;
$button-primary: #20c0e6;
$button-primary-light: #20a0be;
$transparent-button-primary: $button-primary;
$button-primary-on-background: #20c0e6;
$button-secondary: transparent;
$button-info: #20c0e6;
$button-info-font-color: white;
$button-hover-color: $button-info-font-color;
$button-hover-icon: darken($button-info, 10);
$text-regular: #222222;
$text-light-opac: #a1a1a1;
$text-regular-opac: #848484;
$text-white: #ffffff;
$devider-color: #979797;
$regular-opac: #dedede;
$long-text-read-more-background: #f5f5f5;
$textOnSuccessBackground: #222222;
$benefitsBackground: hsla(254, 83%, 72%, 1);
$benefitsText: #7653ed;
$advancedText: #6e49ee;
$regularBenefitsText: #222222;
$benefitsBackgroundLight: #dcd2fb;
$benefitsBackgroundOpac: hsla(254, 83%, 72%, 0.95);
$pieBenefitsShipments: #c8baf8;
$roiTable: #20a0be;
$excelButtonColor: #78be20;
$listItemBackground: #20c0e642;
$statsCardTopLine: hsla(254, 83%, 72%, 1);
$cardBackground: hsla(0, 0, 13%, 0.1);
$cardShadow: rgba(0, 0, 0, 0.13);
$cardShadowHover: rgba(0, 0, 0, 0.28);
$partBackground: #dfe5f3;
$newFeatureTagGradient: linear-gradient(to left, #5daf88, #3abde5);
$takeATourGradient: linear-gradient(to right, #b9e5c7, #8de2f3 100%);
$takeATourBgColor: #b9e5c7;
$takeATourButtonColor: #00c2ea;
$successText: #186a1e;
$successBackground: #caf7ba;
$primaryTableText: #757579;
$primaryTableHeader: #525256;
$primaryBorder: #dbdbe3;
$solutionTabButton: #ff4900;
$combineIconFill: #383838;
$warningIcon: #ffa500;
$progress-bar-gradient: linear-gradient(90deg, #5daf88 0%, #3abde5 153.96%);
$box-header-gradient: linear-gradient(180deg, #64c882, #57ad71);
$button-border-color: #20c0e6;
$quick-upload-feedback-background: linear-gradient(
	90.95deg,
	rgba(100, 200, 130, 0.4) 0%,
	rgba(32, 192, 230, 0.4) 100%
);
$count-note-color: #cf1322;
$disabled-background-color: #dedcdc;
$sidebarIcon: #ffffff;
$sidebarDraggingItemBackground: #222222;

//Table colors
$tableBackground: transparent;
$tableBorderColor: transparent;
$tableHeaderBackground: #dedede66;
$tableButtonsBorder: #fafaff;
$tableButtonsActiveBackground: #404e5a;
$tableButtonsBackground: #dbdbe3;
$tableButtonsHover: #9f9fa7;
$tableButtonsActiveHover: #2c353e;

//Tooltip colors
$tooltip-background-color: #f5f5f5;
$tooltip-title-font-color: #848484;
$tooltip-body-font-color: #222222;

//Tabs colors
$tab-button-active-text-color: $button-primary;
$tab-button-text-color: $text-light-opac;
$tab-button-border-color: $regular-opac;

//Chart color
$chartMethodColor: #ec7d33;
$chartCrossColor: #977cf3;
$chartAMColor: #0ab0ef;

//toolbar
$toolbarBackground: #323e49;
$orientation-icon-color: #222222;

$unlock-background: #ffc700;

//filter block
$grey-search-bar: #6d6d6d;
$background-search-bar: #f7f9ff;

//Select box block
$select-box-padding: 16px;
$select-box-border: #323232;
$select-box-text-color: #323232;
$select-box-selected-color: #20c0e6;
$select-box-selected-background: rgba(32, 192, 230, 0.1);

// analysis page
$darkTextColor: #323232;
$darkGrey: #848484;
$lightGrey: #dedede;
$lightBlue: #20c0e6;
$whiteColor: #ffffff;
$blackColor: #000000;
$benefitIconColor: #7653ed;
$benefitIconBackgroundColor: rgba(151, 124, 243, 0.2);
$whiteBackground: #f1f1f1;
$warnColor: #e8000e;
$hoverColor: #efefef;
$hoverPartColor: #20c0e60d;

//onboarding page
$disabledStepColor: #bdbdbd;
$currentStepColor: #20c0e6;
$completedStepColor: #64c882;
$stepTextColor: #fff;
$stepCardHeaderBackground: linear-gradient(
	135deg,
	rgba(32, 192, 230, 0.16) 0%,
	rgba(100, 200, 130, 0.16) 100%
);
