@import '../../../assets/style/base/colorsTheme.scss';
@import '../../../style/globalStyles.scss';

.items-with-pictures {
  @include flex(flex-start, inherit);

  &--item {
    width: 170px;
    @include flex(center, flex-start);

    &--subtitle {
      @include flex(center, center);

      &--link {
        color: $button-primary;

        &:hover {
          color: $button-primary-light;
        }
      }

      div + div {
        width: 30px;
      }
    }

    &--title {
      color: $text-regular;
      max-width: 150px;
      white-space: nowrap;
      @include text-ellipsis();
    }

    &--content {
      &#{&} {
        height: 156px;
        width: 194px;
        border-radius: 10px;
        background-color: hsla(0, 0%, 87%, 0.38);
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        color: $button-primary;
        transition: all 0.3s ease-in-out;
        outline: auto;
      }

      &:not(.not-interactive) {
        &:hover {
          color: $button-primary-light;
          box-shadow: 0 0 3px 2px hsla(0, 0%, 0%, 0.16);
        }
      }

      &--image {
        padding: 10px;
        height: 156px;
        background-color: $partBackground;
      }

      &.dark-border {
        color: #000000;
      }

      &.light-border {
        color: transparent;
      }
    }
  }
}

.single-item {
  margin: 0 5px;
  @include flex(center, center);
  text-align: center;

  .items-with-pictures--item--title {
    font-weight: 600;
    color: $darkTextColor;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  div {
    justify-content: center;
    text-align: center;
    width: 100%;
    font-size: 14px;
    line-height: 16px;
  }

  .transparent-button {
    font-size: 14px;
    margin-top: 10px;
    text-transform: uppercase;
    text-decoration: underline;
  }
}