@import '../../../assets/style/base/colorsTheme.scss';

.material-selector-class {
  margin-right: 20px;
  width: 105px;
  text-transform: capitalize;

  &.disabledColor {
    color: #f44336 !important;
  }

  &.menu-item {
    &:not(.regular) {
      text-transform: capitalize;
    } 
   }
}
