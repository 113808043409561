@import '../../../assets/style/base/colorsTheme.scss';
@import '../../../assets/style/base/designTheme.scss';
@import '../../../style/globalStyles.scss';

.error-interceptor-block {
	position: absolute;
	width: 100%;
	height: 100%;
	background: rgba(1, 1, 1, 0.44);
  backdrop-filter: blur(3px);
	z-index: 999999;
	@include flex(center, center);

	div {
    width: 400px;
		background: white;
		border-radius: 4px;
		padding: 20px;
		box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
		@include flex(center, center, column);
	}

  h4 {
    width: 70%;
    text-align: center;
  }
}
