@import '../../../assets/style/base/colorsTheme.scss';

.export-button {
	margin: unset !important;
	position: unset !important;
	background-color: transparent !important;
	color: #78be20 !important;
	padding: 6px 13px !important;
	height: 48px;
	text-transform: capitalize !important;
	border-radius: 2px !important;
	border: 2px solid #78be20 !important;
	&:hover {
		box-shadow: 0 14px 26px -12px rgba(120, 190, 32, 0.42),
			0 4px 23px 0px rgba(0, 0, 0, 0.12),
			0 8px 10px -5px rgba(120, 190, 32, 0.2) !important;
	}
}

.export-icon {
	width: 20px;
	height: 20px;
}

.export-csv {
	margin: -3px 8px 0 0;
}

.export-text {
	margin-left: 8px;
	height: 20px;
}
