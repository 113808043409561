@import '../assets/style/base/colorsTheme.scss';
@import '../assets/style/base/designTheme.scss';

@mixin flexible-row() {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

@mixin flexible-column() {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}

@mixin tranparent-button() {
	margin: 0 !important;
	color: $button-primary !important;
	text-transform: capitalize !important;
	font-size: 18px !important;
	padding: 0 !important;
}

@mixin text-ellipsis() {
	text-overflow: ellipsis;
	overflow: hidden;
}

@mixin flex(
	$align: center,
	$justify: center,
	$direction: null,
	$wrap: null,
	$display: flex
) {
	display: $display;
	justify-content: $justify;
	align-items: $align;
	flex-direction: $direction;
	flex-wrap: $wrap;
}

@mixin typography {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

/*  Mixins for adaptive  */
$screen-xs-max: 767px;
$screen-sm-min: 768px;
$screen-md-min: 992px;
$screen-lg-min: 1200px;
$screen-xl-min: 1486px;

@mixin breakpoint($device, $width: 1200px, $height: 800px) {
	@if ($device == xs) {
		// <= 767
		@media only screen and (max-width: $screen-xs-max) {
			@content;
		}
	} @else if ($device == sm) {
		// => 768px
		@media only screen and (min-width: $screen-sm-min) {
			@content;
		}
	} @else if ($device == md) {
		// => 992px
		@media only screen and (min-width: $screen-md-min) {
			@content;
		}
	} @else if ($device == lg) {
		// => 1200px
		@media only screen and (min-width: $screen-lg-min) {
			@content;
		}
	} @else if ($device == xl) {
		// => 1486px
		@media only screen and (min-width: $screen-xl-min) {
			@content;
		}
	} @else if ($device == custom) {
		// custom min width and height
		@media (min-height: $height) and (min-width: $width) {
			@content;
		}
	} @else {
		// custom
		@media only screen and (min-width: $device) {
			@content;
		}
	}
}

@mixin styled-scroll($color: darkgrey, $width: 7px, $background: none) {
	&::-webkit-scrollbar {
		width: $width;
		height: $width;
	}

	@if $background != 'none' {
		::-webkit-scrollbar-track {
			background: $background;
		}
	}

	&::-webkit-scrollbar-thumb {
		background-color: $color;
		border-radius: $width;
	}
}

@mixin overflow-visible() {
	overflow: visible !important;
}

@mixin round-input() {
	background: #ffffff;
	border: 1px solid #323232 !important;
	border-radius: 8px;
}

@mixin font-builder($size: null, $weight: null, $height: null) {
	font-size: $size;
	font-weight: $weight;
	line-height: $height;
}

@mixin modal-header-style() {
	@if $show-popup-header-text-in-box {
		height: 15px;

		button {
			left: 25px;
			top: 15px;

			span {
				svg {
					width: 24px !important;
				}
			}
		}

		&-block {
			background: $box-header-gradient;
			box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
			border-radius: 8px;
			padding: 12px 20px;
			color: $whiteColor;
			position: relative;

			svg {
				&,
				path {
					fill: $whiteColor;
				}
			}

			.modal-header-title {
				color: $whiteColor;
			}
		}
	} @else {
	}
}

/*  Mixins for ordering  */
$orders: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10;

@each $order in $orders {
	.order-#{$order} {
		order: $order;
	}
}

@mixin auto-scroll-with-inner-shadow() {
	overflow: auto;
	background-image: linear-gradient(to top, white, white),
		linear-gradient(to top, white, white),
		linear-gradient(to top, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0)),
		linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0));
	background-position: bottom center, top center, bottom center, top center;
	background-color: white;
	background-repeat: no-repeat;
	background-size: 100% 80px, 100% 40px, 100% 14px, 100% 14px;
	background-attachment: local, local, scroll, scroll;
}
