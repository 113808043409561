@import '../../../../assets/style/base/colorsTheme.scss';
@import '../../../../assets/style/base/designTheme.scss';
@import '../../../../style/globalStyles.scss';

.admin-license-screen {
	width: 100%;

	p {
		@include flex(center, flex-start, row);

		.details-popup-target {
			&:hover {
				cursor: pointer;
			}
		}
		
		.info-box-icon {
			fill: #000000;
		}

		.cost-details-icon {
			width: 16px;
			height: 16px;
		}

		.warning-message {
			font-size: 10px;
			margin-left: 5px;
		}

		&.title {
			font-size: 22px;
			font-weight: 500;
			margin: 15px 0 20px;
		}

		b {
			margin-right: 5px;
		}

		.license-info-text {
			b {
				font-weight: 300;
				margin-right: 0;
			}

			&.red {
				b {
					font-weight: 400;
					color: $warnColor;
				}
			}
		}

		.dot-loading {
			display: none;

			&.animated {
				display: inline-block;

				.dot {
					animation: loading 1.5s infinite;
				}

				span {
					display: inline-block;
					width: 1px;
					height: 1px;
					margin-right: 5px;
					opacity: 0;
					background-color: black;
				}

				.dot:nth-child(2) {
					animation-delay: 0.2s;
				}

				.dot:nth-child(3) {
					animation-delay: 0.4s;
				}

				@keyframes loading {
					0% {
						opacity: 0;
					}
					50% {
						opacity: 1;
					}
					100% {
						opacity: 0;
					}
				}
			}
		}
	}
}
