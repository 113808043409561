@import '../../../../../assets/style/base/colorsTheme.scss';
@import '../../../../../assets/style/base/designTheme.scss';
@import '../../../../../style/globalStyles.scss';

$smallTextSize: 12px;
$mediumTextSize: 14px;
$bigTextSize: 16px;

.pdf {
	&--wrapper {
		overflow: hidden;
		position: relative;
		min-height: 50px;
		&--text {
			@include flex(center, flex-start);
			height: 100%;
		}
		&--loader {
			top: 0;
			left: 0;
			.loader-with-text {
				padding: 0;
				border: none;
				box-shadow: none;
				background: none;
				flex-direction: row;
			}
		}
		&-hidden {
			visibility: hidden;
			height: 0;
		}
	}
	&--document {
		.page-header {
			.line-pdf {
				&.top {
					display: none;
				}
			}

			.text-section {
				@include flex(center, space-between, row);
				margin: 5mm 10mm 10mm;

				h1 {
					@include typography();
					margin: 0;
					font-size: $mediumTextSize;
					max-width: 800px;
					word-break: break-word;
					color: $blackColor;
					font-weight: 400;
					&:nth-of-type(2) {
						font-size: $bigTextSize;
						font-weight: 600;
					}
				}

				p {
					font-size: $smallTextSize;
					color: $blackColor;
					margin: 0;
				}
			}

			img {
				max-height: 160px;
				width: auto;
				height: auto;
				max-width: 100px;
			}
		}
		.page-footer {
			@include flex(center, flex-start, row);
			max-width: 100% !important;
			padding: 0 10mm;

			.small-text {
				font-size: $smallTextSize;
				color: $blackColor;
				margin-right: 15px;
			}

			&__powered {
				text-align: center;
			}

			img {
				height: 25px;
				margin-left: 10px;
			}

			p {
				font-size: 12pt;
				color: $tooltip-title-font-color;
				margin: 0 0 5px 0;
				font-weight: bold;
			}
		}
		.export-pdf {
			.line-pdf {
				background: $lightGrey;
			}
			.space-pdf {
				height: 20pt;
			}
			&--project-summary {
				display: grid;
				grid-template-columns: repeat(2, 50%);
				grid-template-rows: repeat(4, auto);
				grid-auto-flow: column;
				&--row {
					@include text-ellipsis();
					span {
						font-weight: 500;
						color: $blackColor;
					}
				}
			}
			&--parts-summary {
				font-size: $mediumTextSize;
				&--top-block {
					@include flex(center, flex-start, row);
					height: auto;
					&--names {
						@include text-ellipsis();
						font-weight: 600;
						padding-right: 8px;
						border-right: 1px solid $lightGrey;
						max-width: 30%;
					}
					&--count {
						padding-left: 8px;
						min-width: 55px;
						white-space: nowrap;
						text-transform: capitalize;
					}
				}
				&--bottom-block {
					padding: 12px 0;
					color: $blackColor;
					&--status-row {
						@include flex(center, flex-start, row);
						vertical-align: bottom;
						gap: 8px;
						svg {
							width: 14px;
							height: 14px;
							margin-bottom: 2px;
						}
					}
				}
			}
			.parts-list {
				&--header {
					display: grid;
					width: 100%;
					grid-template-columns:
						60px minmax(150px, 1fr) minmax(150px, 1fr) minmax(100px, 1fr)
						repeat(3, 120px);
					&.in-quick-cad-upload {
						grid-template-columns:
							60px minmax(150px, 1fr) minmax(150px, 1fr) minmax(100px, 1fr)
							repeat(3, minmax(120px, 1fr)) 170px;
					}
					&.is-generative-parts {
						grid-template-columns:
							94px
							minmax(125px, 1fr)
							minmax(125px, 1fr)
							minmax(125px, 1fr)
							minmax(125px, 1fr)
							minmax(125px, 1fr)
							minmax(125px, 1fr)
							minmax(125px, 1fr)
							minmax(125px, 1fr)
							minmax(150px, 1fr)
							minmax(150px, 1fr)
							minmax(150px, 1fr)
							70px;
					}
					&--item {
						padding-left: 8px;
						font-weight: 500;
					}
				}
				&--part-row {
					width: 100%;
					display: grid;
					grid-template-columns:
						60px minmax(150px, 1fr) minmax(150px, 1fr) minmax(min-content, 1fr)
						repeat(3, 120px);
					align-items: center;
					border-radius: 8px;
					height: 40px;
					margin: 16px 0 0;
					box-shadow: none;
					border: 1px solid $lightGrey;
					&.in-quick-cad-upload {
						grid-template-columns:
							60px minmax(150px, 1fr) minmax(150px, 1fr) minmax(100px, 1fr)
							repeat(3, minmax(120px, 1fr)) 170px;
					}
					&.is-generative-parts {
						grid-template-columns:
							94px
							minmax(125px, 1fr)
							minmax(125px, 1fr)
							minmax(125px, 1fr)
							minmax(125px, 1fr)
							minmax(125px, 1fr)
							minmax(125px, 1fr)
							minmax(125px, 1fr)
							minmax(125px, 1fr)
							minmax(150px, 1fr)
							minmax(150px, 1fr)
							minmax(150px, 1fr)
							70px;
					}
					&--cell-img {
						width: 60px;
						height: 38px;
						.cell-fallback-img {
							border-radius: 7px 0 0 7px;
							svg {
								transform: scale(1.5);
							}
						}
						.cell-img {
							border-radius: 7px 0 0 7px;
							height: auto;
							width: auto;
							max-width: 100%;
							max-height: 100%;
							object-fit: contain;
						}
					}
					&--cell-text {
						@include flex(center, flex-start, row);
						@include text-ellipsis();
						white-space: nowrap;
						padding-left: 8px;
						font-weight: 400;
						span,
						div {
							@include text-ellipsis();
						}
						&.pure-text {
							display: inline-block;
						}
						&.printer-technology {
							text-transform: uppercase;
						}
						.card--result {
							&-label-text {
								margin-right: 8px;
							}
						}
						.benefit-icon-wrapper {
							min-width: 0;
							svg {
								width: 20px;
								height: 20px;
							}
						}
					}
					.defaultText {
						font-style: italic;
					}
					.grid-list-benefits--icon {
						width: 22px !important;
						height: 22px !important;
					}
				}
			}
			table {
				td {
					margin: 0mm !important;
				}
			}
		}
	}
}
