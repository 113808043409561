@import '../../../style/globalStyles.scss';

.agreement-wrapper {
  &#{&} {
    background-color: white;
    width: 100%;
  }
}
.text-wrapper {
  &#{&} {
    width: 980px !important;
    margin: 0px 0px 46px calc((100% - 980px) * 0.5);

    left: 0px;
    grid-area: 2 / 1 / 3 / 2;
    justify-self: start;
    align-self: start;
    position: relative;
  }
}

.version {
  text-align: $usage-agreement-header-align;
}

.header {
  font-size: 30px;
  font-weight: bold;
  text-align: $usage-agreement-header-align;
}

.text {
  margin: 0;
  line-height: normal;
  letter-spacing: normal;
  font-size: 20px;
  text-align: initial;
}

.logo {
  position: relative;
  right: 420px;
  margin-top: 58px;
  margin-bottom: 51px;

  &:has(+ button) {
    right: 342px;
  }
}
